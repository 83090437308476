<template>
  <v-container class="warehouse-list">
    <h1>{{ $t("warehouse.title") }}</h1>
    <v-row no-gutters v-if="warehouseList && warehouseList.length > 0">
      <v-col
        v-if="showListParsed"
        class="py-0 py-md-3"
        order="2"
        order-sm="1"
        cols="12"
        :sm="showListParsed ? '5' : '0'"
        :xl="showListParsed ? '4' : '0'"
      >
        <v-list
          class="warehouse-list-list"
          :height="mapHeight"
          @click="clicked"
        >
          <AddressFilter
            v-if="google"
            :google="google"
            class="px-4 pb-4"
            @addressToSearchChanged="addressToSearchChanged"
            @resetNearWarehouse="resetNearWarehouse"
          />
          <template v-for="(warehouse, index) in warehouseList">
            <v-list-item
              :key="warehouse.warehouseId"
              class="d-block pt-1"
              two-line
              dense
              @click="toggleInfoWindow(warehouse)"
            >
              <!-- @mouseenter="animateMarker(warehouse, true)"
                  @mouseleave="animateMarker(warehouse, false)" -->

              <!-- <v-list-item-avatar rounded="0">
              <img
                width="24"
                height="35"
                src="/img_layout/map_pointer.png"
                alt="Immagine pin negozio"
                aria-hidden="true"
              />
            </v-list-item-avatar> -->
              <v-list-item-content>
                <v-list-item-title class="text-left font-weight-bold mb-2">
                  {{ warehouse.name || warehouse.address.addressName }}
                </v-list-item-title>
                <v-list-item-subtitle class="warehouse-address">
                  {{ warehouse.address.address1 }},{{
                    warehouse.address.addressNumber
                  }}
                  {{ warehouse.address.city }} ({{
                    warehouse.address.province
                  }})
                  <div
                    class="py-1"
                    v-if="
                      warehouse.metaData.warehouse_locator &&
                        warehouse.metaData.warehouse_locator.PHONE
                    "
                  >
                    Telefono: {{ warehouse.metaData.warehouse_locator.PHONE }}
                  </div>
                  <div class="d-flex justify-end pt-4">
                    <a
                      class="v-btn primary v-size--small white--text"
                      :href="'/punti-vendita/' + warehouse.warehouseId"
                      @click="clicked"
                    >
                      VAI ALLA SCHEDA
                    </a>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              :key="warehouse.warehouseId"
              v-if="index !== warehouseList.length - 1"
            ></v-divider>
          </template>
        </v-list>
      </v-col>
      <v-col
        class="py-0 py-md-3"
        order="1"
        order-sm="2"
        cols="12"
        :sm="showListParsed ? '7' : '0'"
        :xl="showListParsed ? '8' : '0'"
      >
        <GmapMap
          ref="mapRef"
          :center="{
            lat: addressToSearch ? addressToSearch.latitude : 42.52832317314753,
            lng: addressToSearch ? addressToSearch.longitude : 9.025368227739763
          }"
          :zoom="zoomLevel"
          :style="{ width: mapWidth, height: mapHeight }"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUI: false
          }"
        >
          <GmapMarker
            :ref="'warehousMarker_' + warehouse.warehouseId"
            v-for="(warehouse, i) in warehouseList"
            :key="warehouse.warehouseId"
            :position="
              google &&
                new google.maps.LatLng(
                  warehouse.address.latitude,
                  warehouse.address.longitude
                )
            "
            :clickable="true"
            icon="/img_layout/map_pointer.png"
            @click="toggleInfoWindow(warehouse, i)"
          />
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick.stop="infoWinOpen = false"
          >
            {{ selectedWarehouse }}
            Contenuto
          </gmap-info-window>
          <gmap-circle
            v-if="addressToSearch"
            :center="{
              lat: addressToSearch.latitude,
              lng: addressToSearch.longitude
            }"
            :radius="addressToSearch.distance"
            :visible="true"
            :options="{ fillOpacity: 0.3, strokeWeight: 0.3 }"
          >
          </gmap-circle>
        </GmapMap>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.warehouse-list-list {
  height: 380px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: initial;
  }
  overflow-y: auto;
  .v-list-item__title {
    text-transform: uppercase;
    color: var(--v-primary-base);
    font-size: 16px;
    font-weight: bold;
  }
  .warehouse-address {
    font-size: 14px;
    line-height: 1.3;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      white-space: normal;
    }
  }
  .v-avatar {
    padding: 2px;
    margin: 0;
    img {
      width: 24px;
      height: 34px;
    }
  }
}
.gm-style-iw .gm-style-iw {
  max-height: 180px !important;
}
.gm-style {
  .gm-style-iw-c {
    max-height: 180px !important;
    .gm-style-iw-d {
      max-height: 180px !important;
    }
  }
}
#infoWindowDialog {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--v-primary-base);
  }

  a {
    cursor: pointer;
  }
}
</style>
<script>
import AddressFilter from "@/components/address/AddressFilter.vue";

import AddressService from "~/service/addressService";

import { gmapApi } from "vue2-google-maps";
import forEach from "lodash/forEach";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "WarehouseMap",
  components: { AddressFilter },
  props: {
    regionId: { type: String, required: false },
    provinceId: { type: String, required: false },
    parentAddressClassId: { type: String, required: false },
    showLocker: { type: String, default: "false" }
  },
  mixins: [clickHandler],
  data() {
    return {
      showListParsed: true,
      showLockerParsed: false,
      warehouseList: [],
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      selectedWarehouse: null,
      selectedRegionId: null,

      addressToSearch: null
    };
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return this.$vuetify.breakpoint.xsOnly ? "300px" : "480px";
    },
    pageContent() {
      return this.page && this.page.content ? this.page.content.rendered : "";
    },
    pageTitle() {
      return this.page && this.page.title ? this.page.title.rendered : "";
    },
    zoomLevel() {
      return this.$vuetify.breakpoint.xsOnly ? 5 : 6;
    }
  },
  methods: {
    resetNearWarehouse() {
      this.addressToSearch = null;
    },
    async addressToSearchChanged(addressToSearch) {
      this.addressToSearch = addressToSearch;
      await this.findWarehouse();
    },
    async findWarehouse() {
      let searchParams = {
        province_id: this.provinceId,
        parent_address_class_id: this.parentAddressClassId,
        region_id: this.regionId
      };
      if (this.addressToSearch) {
        searchParams["parent_latitude"] = this.addressToSearch.latitude;
        searchParams["parent_longitude"] = this.addressToSearch.longitude;
        searchParams["parent_distance"] = this.addressToSearch.distance;
      }
      let data = await AddressService.findWarehouse(searchParams);
      this.warehouseList = data.warehouses;
    },
    async findLocker() {
      this.warehouseList = [];
      let data = await AddressService.getAddressByDeliveryService(4);
      forEach(data.addresses, a => {
        this.warehouseList.push({ metaData: {}, address: a });
      });
    },
    animateMarker(warehouse, status) {
      if (status) {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = this.google.maps.Animation.BOUNCE;
      } else {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = null;
      }
    },
    toggleInfoWindow(warehouse) {
      this.infoWindowPos = {
        lat: warehouse.address.latitude,
        lng: warehouse.address.longitude
      };

      let infoWindowBtn = `<a class='v-btn v-btn--text secondary--text v-size--small' target='system' href='https://www.google.it/maps/dir//${warehouse.name}@/${warehouse.address.latitude},${warehouse.address.longitude}'>
            COME ARRIVARE
          </a><span width="10px"></span>`;
      this.infoOptions.content = `<div id='infoWindowDialog' style='font-family: Open Sans, arial, sans-serif'>
          <h3 class='d-flex align-center' style='line-height: 1.2rem;'>
            ${warehouse.name || warehouse.address.addressName}
          </h3>
          <div class='mb-1'>
            ${warehouse.address.address1} ${
        warehouse.address.addressNumber
      } <br/>
            ${warehouse.address.city} ( ${warehouse.address.province} ) ${
        warehouse.address.postalcode
      } <br/>
          </div>
          ${infoWindowBtn}
        </div>`;

      //check if its the same marker that was selected if yes toggle
      if (
        this.selectedWarehouse &&
        this.selectedWarehouse.warehouseId == warehouse.warehouseId
      ) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.selectedWarehouse = warehouse;
      }
    }
  },
  mounted() {
    this.findWarehouse();
  }
};
</script>
